'use client'

import clsx from 'clsx'
import { FormField } from '@/lib/types'
import DatePicker from '../DatePicker'
import Input from './Input'
import RadioButtons from './RadioButtons'
import Select from './Select'

type ConditionalInputProps = FormField &
  React.HTMLAttributes<HTMLInputElement> & {
    fields: FormField[]
    value:
      | string
      | number
      | boolean
      | object
      | string[]
      | readonly File[]
      | FileList
      | null
      | undefined
    styleOverride?: string
  }

export default function ConditionalInput(props: ConditionalInputProps) {
  const {
    label,
    name,
    placeholder,
    inputProps,
    handleChange,
    required,
    fields,
    value,
    hint,
    error,
  } = props

  if (inputProps) {
    const {
      dependentOn,
      dependencyTrigger,
      secondaryDependency,
      secondaryDependencyTrigger,
      type,
      inputSelectOptions,
      styleOverride,
    } = inputProps

    let active = false
    let secondaryActive = false

    if (fields) {
      if (secondaryDependency && secondaryDependencyTrigger) {
        active =
          fields.find((field) => field.name === dependentOn)?.value ===
            dependencyTrigger &&
          fields.find((field) => field.name === secondaryDependency)?.value ===
            secondaryDependencyTrigger
        secondaryActive = active
      } else {
        const dependentOnField = fields.find(
          (field) => field.name === dependentOn,
        )

        secondaryActive = false
        active = dependentOnField?.value === dependencyTrigger
      }
    }

    return (
      <div>
        <div className="w-full">
          {active && type === 'select' && (
            <div
              className={clsx(
                'mb-20 flex w-full lg:mb-28',
                active && '',
                secondaryActive && ' !mb-[20px]',
                active && styleOverride,
              )}
            >
              <Select
                label={label}
                name={name}
                placeholder={placeholder}
                inputProps={inputProps}
                handleChange={handleChange}
                required={required}
                options={inputSelectOptions}
                variant="standard"
                value={value}
                type="select"
                error={error}
              />
            </div>
          )}
          {active && type === 'number' && (
            <div
              className={clsx(
                'mb-20 lg:mb-28',
                active && '2',

                secondaryActive && ' !mb-[20px] mt-[-10px] lg:mt-[-22px]',
                secondaryActive && styleOverride,
              )}
            >
              <Input
                label={label}
                name={name}
                placeholder={placeholder}
                inputProps={inputProps}
                handleChange={handleChange}
                required={required}
                value={value}
                type="number"
                hint={hint}
                error={error}
              />
            </div>
          )}
          {active && type === 'text' && (
            <div>
              <Input
                label={label}
                name={name}
                placeholder={placeholder}
                inputProps={inputProps}
                handleChange={handleChange}
                required={required}
                value={value}
                type="text"
                error={error}
              />
            </div>
          )}
          {active && type === 'date' && (
            <div>
              <DatePicker
                value={value}
                handleChange={handleChange}
                required={required}
              />
            </div>
          )}
          {active && type === 'radio' && (
            <div className="mb-20 flex w-full lg:mb-28">
              <RadioButtons
                name={name}
                label={label}
                required={required}
                value={value}
                inputProps={inputProps}
                type={type}
                handleChange={handleChange}
              />
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return null
  }
}

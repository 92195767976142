import { Dispatch, SetStateAction } from 'react'
import { z } from 'zod'
import { FormField } from '@/lib/types'

export const validateFormSchema = (
  schema: z.ZodSchema,
  fields: FormField[],
  setFields: Dispatch<SetStateAction<FormField[]>>,
) => {
  const values = Object.fromEntries(
    fields.map((field) => [field.name, field.value]),
  )

  const validation = schema.safeParse(values) as z.SafeParseReturnType<
    object,
    object
  >

  if (!validation.success) {
    //INVALID
    const errors = validation.error.flatten().fieldErrors as object
    setFields((prevFields) => {
      return prevFields.map((field: FormField) => {
        if (errors[field.name as keyof typeof errors]) {
          field.inputProps?.inputRef?.current?.focus()
          field.inputProps?.inputRef?.current?.scrollIntoView({ block: 'end' })

          return {
            ...field,
            error: errors[field.name as keyof typeof errors],
          }
        }

        return {
          ...field,
          error: errors[field.name as keyof typeof errors],
        }
      })
    })
  } else {
    //VALID
    setFields((prevFields) => {
      return prevFields.map((field: FormField) => {
        return {
          ...field,
          error: undefined,
        }
      })
    })
  }

  return {
    success: validation.success,
    data: validation.success === true ? validation.data : {},
    error: validation.success === false ? validation.error : {},
  }
}

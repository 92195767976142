'use client'

import { DialogContent, DialogOverlay } from '@reach/dialog'
import '@reach/dialog/styles.css'
import { WalletContextState } from '@solana/wallet-adapter-react'
import clsx from 'clsx'
import React, { PropsWithChildren, ReactElement } from 'react'
import Button from '@/components/tokens/Button'
import Icon from '@/components/tokens/Icon'
import {
  ButtonColor,
  ButtonFont,
  ButtonResponsiveness,
  ButtonVariant,
  EventCategory,
  ModalType,
} from '@/lib/types'
import { logEvent } from '@/lib/utils/event'
import SearchInput from './tokens/SearchInput'

export type ModalProps = {
  label?: string | ReactElement
  modalHeader?: string | ReactElement
  modalLabelColor?: ButtonColor
  modalLabelTextColor?: ButtonColor
  modalLabelFont?: ButtonFont
  modalLabelResponsiveness?: ButtonResponsiveness
  modalLabelVariant?: ButtonVariant
  modalType?: ModalType
  backgroundOverride?: boolean
  onClick?: () => void
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  searchInput?: boolean
  searchInputPlaceholder?: string
  searchInputName?: string
  searchInputValue?: string
  setSearchInputValue?: React.Dispatch<React.SetStateAction<string>>
  searchInputHandleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  searchInputHandleClear?: (e: React.MouseEvent) => void
  searchInputStyleOverride?: string
  hideTrigger?: boolean
  icon?: string
  disabled?: boolean
  disableKeydownSubmit?: boolean
  modalStyleOverride?: string
  closeCallback?: () => void
  inSettings?: boolean
  hasUnsavedChanges?: boolean
  eventAction?: string
  eventCategory?: EventCategory
  wallet?: WalletContextState
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  eventParams?: any
}

export default function Modal(props: PropsWithChildren<ModalProps>) {
  const {
    label,
    children,
    modalHeader,
    modalLabelResponsiveness,
    modalLabelVariant,
    modalLabelFont,
    modalLabelTextColor,
    modalType = ModalType.Default,
    backgroundOverride,
    onClick,
    disabled = false,
    isOpen,
    setIsOpen,
    modalLabelColor,
    searchInput,
    searchInputPlaceholder,
    searchInputName,
    searchInputValue,
    setSearchInputValue,
    searchInputHandleChange,
    searchInputHandleClear,
    searchInputStyleOverride,
    hideTrigger,
    icon,
    modalStyleOverride,
    closeCallback,
    inSettings = false, //rename to inTabs
    hasUnsavedChanges = false,
    eventAction,
    eventCategory,
    wallet,
    eventParams,
  } = props

  const toggleModal = () => {
    if (isOpen) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  return (
    <div>
      {!hideTrigger && (
        <Button
          disabled={disabled}
          color={
            (modalLabelColor === ButtonColor.Blue && ButtonColor.Blue) ||
            (modalLabelColor === ButtonColor.Black && ButtonColor.Black) ||
            (modalLabelColor === ButtonColor.Solid && ButtonColor.Solid) ||
            (modalLabelColor === ButtonColor.Grey && ButtonColor.Grey) ||
            (modalLabelColor === ButtonColor.White && ButtonColor.White) ||
            (modalLabelColor === ButtonColor.Warning && ButtonColor.Warning) ||
            ButtonColor.Black
          }
          onClick={(e) => {
            toggleModal()

            if (e.stopPropagation) {
              e.stopPropagation()

              if (eventAction && eventCategory) {
                logEvent(eventAction, eventCategory, wallet, eventParams)
              }
            }

            if (onClick) {
              onClick()
            }
          }}
          label={label}
          responsiveness={
            (modalLabelResponsiveness === ButtonResponsiveness.MinW &&
              ButtonResponsiveness.MinW) ||
            ButtonResponsiveness.Full
          }
          textColor={
            (modalLabelTextColor === ButtonColor.Black && ButtonColor.Black) ||
            (modalLabelTextColor === ButtonColor.White && ButtonColor.White) ||
            (modalLabelTextColor === ButtonColor.Blue && ButtonColor.Blue) ||
            (modalLabelTextColor === ButtonColor.Grey && ButtonColor.Grey) ||
            ButtonColor.Black
          }
          font={
            (modalLabelFont === ButtonFont.Body1 && ButtonFont.Body1) ||
            (modalLabelFont === ButtonFont.UI1 && ButtonFont.UI1) ||
            (modalLabelFont === ButtonFont.Caption1 && ButtonFont.Caption1) ||
            ButtonFont.Body1
          }
          variant={
            (modalLabelVariant === ButtonVariant.Unstyled &&
              ButtonVariant.Unstyled) ||
            (modalLabelVariant === ButtonVariant.Large &&
              ButtonVariant.Large) ||
            (modalLabelVariant === ButtonVariant.Small &&
              ButtonVariant.Small) ||
            (modalLabelVariant === ButtonVariant.Square &&
              ButtonVariant.Square) ||
            (modalLabelVariant === ButtonVariant.Icon && ButtonVariant.Icon) ||
            ButtonVariant.Unstyled
          }
          icon={icon}
        />
      )}

      <DialogOverlay
        isOpen={isOpen}
        onDismiss={() => {
          if (hasUnsavedChanges) {
            if (
              confirm(
                `You have unsaved changes.  Are you sure you want to exit?\n\nIf you select "OK", your changes will be lost.`,
              )
            ) {
              toggleModal()
            }
          }
        }}
        className={clsx(' !z-10 !overflow-hidden md:mb-[64px]')}
        onClick={() => {
          if (!hasUnsavedChanges) {
            if (closeCallback) {
              closeCallback()

              return
            }

            toggleModal()

            return false
          }
        }}
        style={{
          background: backgroundOverride
            ? 'transparent'
            : 'rgba(230, 230, 230, 0.6)',
        }}
      >
        <DialogContent //DO NOT TOUCH -- being rebuilt
          className={clsx(
            (modalType === ModalType.Login || modalType === ModalType.Wallet) &&
              ' h-[100vh] !w-[100vw]  !p-12 md:!mt-28 md:!h-auto md:!w-[548px] md:!p-20',

            modalType === ModalType.ViewAll &&
              'relative  !w-[100vw] overflow-hidden  bg-white !px-12 !pt-12 sm:!mt-[86px] md:!mb-[64px]  md:!mt-28 md:!w-[548px]  md:!px-[20px] md:!pb-[20px] md:!pt-20',

            modalType === ModalType.Default &&
              'relative  !w-[100vw] overflow-hidden  bg-white !px-12 !pt-12 sm:!mt-[86px] md:!mb-[64px]  md:!mt-28 md:!w-[548px]  md:!px-[20px] md:!pb-[20px] md:!pt-20',
            'h-[100vh] overflow-hidden  md:h-min md:!max-h-[calc(100vh_-_119px)]', //<--default style
            inSettings && 'over-flow-hidden  md:max-h-none',
            modalStyleOverride,
          )}
        >
          <div className="flex h-full flex-col">
            <div className="relative flex flex-row justify-between">
              {' '}
              {modalHeader && (
                <p
                  className={clsx(
                    'ui-1',
                    modalType === ModalType.Default && 'pb-[60px]',
                    modalType !== ModalType.Default && 'pb-12',
                  )}
                >
                  {modalHeader}
                </p>
              )}
              <button
                onClick={() => {
                  toggleModal()

                  if (closeCallback) {
                    closeCallback()

                    return
                  }

                  return false
                }}
                className="absolute right-0 "
              >
                <span className="hitBox20 w-full">
                  <Icon
                    name="closeLarge"
                    height={16}
                    width={16}
                    className="black h-16 w-16 md:hover:opacity-70"
                  />
                </span>
              </button>
            </div>
            <div
              className={clsx(
                //DO NOT TOUCH -- being rebuilt
                modalType === ModalType.Default &&
                  'no-scrollbar flex flex-col ',
                'no-scrollbar flex flex-col ',
                modalType === ModalType.Login && 'flex h-full flex-col',

                'h-full max-h-none overflow-y-scroll  md:!max-h-[calc(100dvh_-_235px)] md:!overflow-hidden', //default inhertiance
                modalType === ModalType.ViewAll &&
                  'md:!max-h-[calc(100vh_-_204px) !overflow-y-scroll  md:!overflow-y-scroll',
                modalType === ModalType.Wallet &&
                  ' overflow-x-hidden  md:!overflow-y-scroll',
              )}
            >
              {searchInput && (
                <div
                  className={`mb-60 mt-48 md:mt-32 ${searchInputStyleOverride}`} //this needs to be more flexible for style, viewAll containers are taking too much precedent
                >
                  <SearchInput
                    placeholder={searchInputPlaceholder ?? ''}
                    name={searchInputName ?? ''}
                    query={searchInputValue ?? ''}
                    setQuery={setSearchInputValue}
                    handleChange={(e) => searchInputHandleChange?.(e)}
                    handleClear={(e) => searchInputHandleClear?.(e)}
                    inputStyleOverride="!w-full h-[40px]"
                  />
                </div>
              )}
              <div className={clsx(!searchInput && 'mt-48 md:mt-32')}>
                {children}
              </div>
            </div>
          </div>
        </DialogContent>
      </DialogOverlay>
    </div>
  )
}

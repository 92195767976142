'use client'

import { createContext, useRef, useState } from 'react'
import { Toast, ToastVariant } from '@/lib/types'

interface ToastContextValues {
  toasts: Toast[]
  addToast: ({
    message,
    variant,
    image,
    link,
  }: {
    message: string
    variant: ToastVariant
    image?: string
    link?: string
  }) => void
  showProfileSettingsPopup: boolean
  setShowProfileSettingsPopup: (show: boolean) => void
}

const ToastContext = createContext<ToastContextValues>({
  toasts: [] as Toast[],
  addToast: () => undefined,
  showProfileSettingsPopup: false,
  setShowProfileSettingsPopup: () => undefined,
})

const ToastContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState([] as Toast[])
  const toastsRef = useRef<Toast[]>([])

  const [showProfileSettingsPopup, setShowProfileSettingsPopup] =
    useState(false)

  const addToast = ({
    message,
    variant,
    image,
    link,
  }: {
    message: string
    variant: ToastVariant
    image?: string
    link?: string
  }) => {
    const toast: Toast = {
      message,
      variant,
      id: crypto.randomUUID(),
      image,
      link,
      handleDeleteToast: () => deleteToast(toast.id),
    }

    toastsRef.current = [...toastsRef.current, toast]
    setToasts(toastsRef.current)

    const timer = setTimeout(() => {
      if (
        toast.variant !== 'processing' &&
        toast.variant !== 'uploadComplete'
      ) {
        deleteToast(toast.id)
      }
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }

  const deleteToast = (id: string) => {
    toastsRef.current = toastsRef.current.filter((e) => e.id !== id)
    setToasts(toastsRef.current)
  }

  return (
    <ToastContext.Provider
      value={{
        toasts,
        addToast,
        showProfileSettingsPopup,
        setShowProfileSettingsPopup,
      }}
    >
      {children}
    </ToastContext.Provider>
  )
}

export default {
  Context: ToastContext,
  Provider: ToastContextProvider,
}

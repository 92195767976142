'use client'

import clsx from 'clsx'
import { FormField } from '@/lib/types'

type RadioButtonProps = FormField & React.HTMLAttributes<HTMLInputElement>

export default function RadioButtons(props: RadioButtonProps) {
  const { name, label, value, required, handleChange, inputProps } = props

  const FilledRadio = () => {
    return (
      <span className="border-box ml-4 h-12 w-12 rounded-full border-1 bg-black" />
    )
  }

  const EmptyRadio = () => {
    return (
      <span className="border-box ml-4 h-12 w-12 rounded-full border-1 bg-white" />
    )
  }

  return (
    <div className="body-1 flex flex-col">
      <label htmlFor={name} className="ui-1 mb-8">
        {label}
        {required && <span className=""> *</span>}
      </label>
      <fieldset name={name} className={clsx('flex')}>
        {inputProps?.inputSelectOptions?.map(
          (option: { label: string; value: string }, index: number) => {
            return (
              <div className=" mr-12 flex items-center" key={index}>
                <input
                  id={option?.label + index}
                  name={name}
                  type="radio"
                  value={option.value}
                  onChange={handleChange}
                  checked={value === option.value}
                  className="hidden"
                />
                <label
                  htmlFor={option.label + index}
                  className="body-1 flex w-full flex-row items-center"
                >
                  {value === option.value ? <FilledRadio /> : <EmptyRadio />}

                  <span className="ml-8">{option.label}</span>
                </label>
              </div>
            )
          },
        )}
      </fieldset>
    </div>
  )
}

'use client'

import clsx from 'clsx'
import React, { useState } from 'react'
import { FormField } from '@/lib/types'
import Icon from './Icon'

type InputProps = FormField & React.HTMLAttributes<HTMLInputElement>

export default function TagInput(props: InputProps) {
  const { error, handleTagFieldChange, name, value, label, required } = props
  const [tags, setTags] = useState<string[]>(value || [])

  const formatPlaceholderString = (tags: string[]) => {
    if (tags.length === 0) {
      return 'Enter up to 4 tags'
    } else if (tags.length > 0 && tags.length < 3) {
      return `Enter up to ${4 - tags.length} more tags`
    } else if (tags.length === 3) {
      return `Enter 1 more tag`
    } else {
      return ''
    }
  }

  const addTags = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.currentTarget instanceof HTMLInputElement) {
      const isBackspace = event.keyCode === 8

      if (isBackspace && event.currentTarget.value === '' && tags.length > 0) {
        const lastTagIndex = tags.length - 1

        const filteredTags = [
          ...tags.slice(0, lastTagIndex),
          ...tags.slice(lastTagIndex + 1),
        ]

        setTags(filteredTags)

        if (handleTagFieldChange) {
          handleTagFieldChange('tags', filteredTags)
        }
      } else if (
        (event.keyCode === 13 || event.keyCode === 32) &&
        event.currentTarget.value.length > 0 &&
        tags.length < 4
      ) {
        const value = event?.currentTarget.value.replaceAll(' ', '')

        if (value.length > 0) {
          setTags([...tags, value])

          if (handleTagFieldChange) {
            handleTagFieldChange('tags', [...tags, value])
          }

          event.currentTarget.value = ''
        }
      }
    }
  }

  const removeTags = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
  ) => {
    e.preventDefault()
    const filteredTags = [...tags.filter((tag) => tags.indexOf(tag) !== index)]
    setTags(filteredTags)

    if (handleTagFieldChange) {
      handleTagFieldChange('tags', filteredTags)
    }
  }

  const inputClass = clsx(
    'mb-28 flex w-full no-scrollbar flex items-center border-1 body-1 placeholder-grey-45 focus:outline-none w-full box-border overflow-x-auto',
    tags.length > 0 ? 'pl-8' : '',
    tags.length === 4 && 'py-10',
    error ? 'border-red-55' : 'border-black',
  )

  return (
    <div>
      <div className="body-1 flex flex-col">
        {label !== '' && (
          <label htmlFor={name} className="!ui-1 pb-8 ">
            {label}
            {required && <span className=""> *</span>}
          </label>
        )}
      </div>

      <div className="overflow-x-auto">
        <div className={inputClass}>
          {tags && (
            <ul className="flex flex-row">
              {tags.map((tag, index) => (
                <li key={index} className="mr-4  whitespace-nowrap">
                  <span className="body-1 flex h-[20px] items-center  bg-blue-5 px-[4px] text-blue-82">
                    #{tag}
                    <button
                      onClick={(e) => removeTags(e, index)}
                      className="ml-4 "
                    >
                      <Icon
                        name="closeSmall"
                        className="blue-65"
                        height={20}
                        width={20}
                      />
                    </button>
                  </span>
                </li>
              ))}
            </ul>
          )}
          {tags.length < 4 && (
            <div className="flex-grow border-none px-[12px] py-[9px] outline-none">
              <input
                type="text"
                onKeyUp={(event) => addTags(event)}
                name={name}
                placeholder={formatPlaceholderString(tags)}
                className="body-1 w-full min-w-[300px] placeholder-grey-45 focus:outline-none"
                pattern="[A-Za-z0-9]+" // only allow alphanumeric characters
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

import {
  FlowType,
  FormField,
  HubCreateFormFieldData,
  ReleaseCreateFormFieldData,
} from '../types'

export const convertFieldsToObject = (
  fields?: FormField[],
  flowType?: FlowType,
) => {
  if (flowType === FlowType.Release) {
    const data = {} as ReleaseCreateFormFieldData

    if (!fields) return data

    const editionTypeField = fields.find(
      (field) => field.name === 'editionType',
    )

    const editionSizeField = fields.find(
      (field) => field.name === 'editionSize',
    )

    const editionType = editionTypeField ? editionTypeField.value : undefined
    const editionSize = editionSizeField ? editionSizeField.value : undefined
    fields.forEach((field: FormField) => {
      switch (field.name) {
        case 'artwork':
          data[field.name] = field.value.croppedImage
          break
        case 'price':
          data.retailPrice = Number(field.value)
          break
        case 'editionSize':
          if (editionType === 'limitedEdition') {
            data.amount = Number(field.value)
          } else {
            data.amount = -1
          }
        case 'customEditionSize':
          if (editionType === 'limitedEdition' && editionSize === 'custom') {
            if (field.value) {
              data.amount = Number(field.value)
            }
          }

          break
        case 'standardResalePercentage':
          const resalePercentage = field.value as string
          data.resalePercentage = Number(resalePercentage?.replace('%', ''))
          break
        case 'catalogNumber':
        case 'title':
        case 'tracks':
        case 'hub':
        case 'tags':
        case 'description':
          data[field.name] = field.value === undefined ? '' : field.value
          break
        case 'isUsdc':
          data[field.name] = field.value
          break
        case 'date':
          if (field.value) {
            data.date = Math.floor(
              new Date(field.value).getTime() / 1000,
            ).toString()
          }

          break
        default:
          break
      }
    })

    return data
  } else if (flowType === FlowType.Hub) {
    const data = {} as HubCreateFormFieldData

    if (!fields) return data

    fields.forEach((field: FormField) => {
      switch (field.name) {
        case 'image':
          data[field.name] = field.value?.croppedImage
          break
        case 'name':
        case 'permalink':
        case 'description':
          data[field.name] = field.value === undefined ? '' : field.value
        default:
          break
      }
    })

    return data
  } else if (flowType === FlowType.ArticleCreate) {
    return
  } else {
    return {}
  }
}

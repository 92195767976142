import Image from 'next/image'
import React from 'react'
import {
  Article,
  FlowType,
  FormField,
  Hub,
  HubCreateFormFieldData,
  ReleaseCreateFormFieldData,
} from '@/lib/types'
import { convertFieldsToObject } from '@/lib/utils/convertFieldsToObject'

type ReviewProps = {
  type: FlowType
  fields?: FormField[]
  hub?: string | Hub
  article?: Article
  accountHubs?: Hub[]
  isUsdc?: boolean
}

export default function Review(props: ReviewProps) {
  const { type, fields, hub, article, accountHubs } = props
  let displayName = typeof hub === 'string' ? hub : hub?.data?.displayName

  if (!type) return null

  const renderReviewCopy = () => {
    switch (type) {
      case FlowType.Release:
        return 'Please double check the following information before publishing your release. Your music will be stored permanently on-chain and cannot be deleted, but some details can be edited on Nina.'
      case FlowType.Hub:
        return 'Please double check the following information before creating your hub. Hubs are stored permanently on-chain and cannot be deleted, but some details can be edited on Nina.'
      case FlowType.ArticleCreate:
        return 'Please double check the following information before publishing your article.'
      default:
        return 'Review your details'
    }
  }

  const renderReviewMarkup = (type: FlowType) => {
    switch (type) {
      case FlowType.Release:
        const {
          artwork,
          title,
          hub,
          retailPrice,
          amount,
          resalePercentage,
          catalogNumber,
          date,
          isUsdc,
        } = convertFieldsToObject(fields, type) as ReleaseCreateFormFieldData

        const releaseDateString = new Date(
          parseInt(date) * 1000,
        ).toLocaleDateString()

        const releaseTimeString = new Date(
          parseInt(date) * 1000,
        ).toLocaleTimeString()

        if (!displayName) {
          displayName = accountHubs?.find((h) => h.publicKey === hub)?.data
            .displayName
        }

        return (
          <>
            <div className="mb-28 flex space-x-20">
              <Image
                src={artwork?.preview}
                width={120}
                height={120}
                alt="release artwork preview"
                className=" imageBorder"
              />

              <div>
                <h1 className="display-1">{title}</h1>
                <h1 className="display-1 text-blue-82">{displayName}</h1>
              </div>
            </div>
            <div className="">
              <ul className="uppercase text-grey-45">
                <li className="flex justify-between border-b-1 border-grey-10 py-8">
                  <p className="ui-1">Price</p>{' '}
                  <span className="ui-1">
                    {isUsdc && '$'}
                    {retailPrice}
                    {!isUsdc && ' SOL'}
                  </span>
                </li>
                <li className="flex justify-between border-b-1 border-grey-10 py-8">
                  <p className="ui-1">Edition Type</p>{' '}
                  <span className="ui-1">
                    {amount === -1 ? 'Open Edition' : 'Limited Edition'}
                  </span>
                </li>
                <li className="flex justify-between border-b-1 border-grey-10 py-8">
                  <p className="ui-1">Edition Size</p>{' '}
                  <span className="ui-1">
                    {amount !== -1 ? amount : 'unlimited'}
                  </span>
                </li>
                <li className="flex justify-between border-b-1 border-grey-10 py-8">
                  <p className="ui-1">Resale Percentage </p>{' '}
                  <span className="ui-1">{resalePercentage || 5}%</span>
                </li>
                <li className="flex justify-between border-b-1 border-grey-10 py-8">
                  <p className="ui-1">Catalog No. </p>{' '}
                  <span className="ui-1">{catalogNumber}</span>
                </li>
                {date && (
                  <>
                    <li className="flex justify-between border-b-1 border-grey-10 py-8">
                      <p className="ui-1">Schedule Release Date</p>{' '}
                      <span className="ui-1">{releaseDateString}</span>
                    </li>
                    <li className="flex justify-between border-b-1 border-grey-10 py-8">
                      <p className="ui-1">Schedule Release Time</p>{' '}
                      <span className="ui-1">{releaseTimeString}</span>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </>
        )
      case FlowType.Hub:
        const { permalink } = convertFieldsToObject(
          fields,
          type,
        ) as HubCreateFormFieldData

        return (
          <div>
            <p className="ui-1">permalink</p>

            <div className="my-8 flex space-x-20">
              <p className="w-full bg-grey-3 px-12 py-10">
                ninaprotocol.com/hubs/{permalink}
              </p>
            </div>
            <p className="hint">This cannot be changed</p>
          </div>
        )
      case FlowType.ArticleCreate:
        if (!article) return null

        const articleDateString = new Date(
          parseInt(article.date) * 1000,
        ).toLocaleDateString()

        const articleTimeString = new Date(
          parseInt(article.date) * 1000,
        ).toLocaleTimeString()

        return (
          <div>
            <div className="flex w-full gap-x-20">
              <div className="border-grey-34 h-[67px] w-[120px] border-1">
                {article.heroImage?.preview && (
                  <Image
                    src={article.heroImage?.preview || ' '}
                    width={120}
                    height={120}
                    alt="article preview"
                    className="imageBorder w-120"
                  />
                )}
              </div>
              <div>
                <p className="display-1">{article.title}</p>

                <p className="display-1 text-blue-82">{displayName}</p>
              </div>
            </div>

            <div className="mt-28">
              <ul className="uppercase text-grey-45">
                <li className="ui-i flex justify-between border-b-1 border-grey-10 py-8">
                  <p>Author</p> <span>{article.authorDisplayName}</span>
                </li>
                {article.date && (
                  <>
                    <li className="ui-i flex justify-between border-b-1 border-grey-10 py-8">
                      <p>Schedule Release Date</p>{' '}
                      <span>{articleDateString}</span>
                    </li>
                    <li className="ui-i flex justify-between border-b-1 border-grey-10 py-8">
                      <p>Schedule Release Time</p>{' '}
                      <span>{articleTimeString}</span>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        )
    }
  }

  return (
    <div className="flex flex-col">
      <div className="!body-1 mb-28 border-1 border-red-55 px-12 py-10">
        {renderReviewCopy()}
      </div>

      <p className="body-1">{renderReviewMarkup(type)}</p>
    </div>
  )
}

'use client'

import clsx from 'clsx'
import React, { ChangeEventHandler, useRef } from 'react'

type DatePickerProps = {
  handleChange?: ChangeEventHandler<HTMLInputElement>
  value: string
  required?: boolean
}

const DatePicker = ({ handleChange, value, required }: DatePickerProps) => {
  const dateRef = useRef<HTMLInputElement>(null)

  return (
    <div className="mb-28 flex w-full max-w-full flex-col overflow-hidden ">
      <label htmlFor="datetime-local" className="ui-1 mb-2 text-black">
        Date & Time {required && <span> *</span>}
      </label>
      <div className="relative !w-full">
        <>
          <input
            className={clsx(
              'h-40 w-full  !rounded-none border-1 bg-white focus:border-black focus:outline-none focus:ring-0',
              'ui-1',
            )}
            name="date"
            id="datetime-local"
            type="datetime-local"
            placeholder="Pick a date for your release"
            ref={dateRef}
            value={value || new Date().toLocaleDateString()}
            onChange={handleChange}
            min={new Date().toISOString().slice(0, 16)}
          />
        </>
      </div>
    </div>
  )
}

export default DatePicker

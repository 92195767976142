'use client'

import * as Select from '@radix-ui/react-select'
import { InputSelectOptionsProps } from '@/lib/types'
import Icon from './Icon'

type InputSelectProps = {
  handleChangeWithoutEvent?: (
    e: React.ChangeEvent<HTMLElement> | null,
    data?: {
      name?: string
      value: string
    },
  ) => void
  options: InputSelectOptionsProps
  value: string
  name?: string
}

export default function InputSelect(props: InputSelectProps) {
  const { handleChangeWithoutEvent, options, value, name } = props

  return (
    <div className="z-50 ml-4 ">
      <Select.Root
        defaultValue={value}
        onValueChange={(value: string) => {
          const data = {
            name,
            value,
          }

          if (handleChangeWithoutEvent) {
            handleChangeWithoutEvent(null, data)
          }
        }}
      >
        <Select.Trigger className="body-1 z-50 flex h-[20px] max-w-[62px] cursor-pointer flex-row justify-end bg-white uppercase text-black">
          <Select.Value className="body-1">{value}</Select.Value>
          <Select.SelectIcon>
            <Icon
              name="chevronDownHeavy"
              width={20}
              height={20}
              className="black"
            />
          </Select.SelectIcon>
        </Select.Trigger>
        <Select.Content className="body-1 z-50 h-[20px] w-[62px] cursor-pointer bg-white text-black">
          <Select.Group className="body-1 z-50 h-[20px] w-[62px] cursor-pointer bg-white text-black">
            {Object.keys(options).map((option, index) => {
              return (
                <Select.Item
                  key={index}
                  id={options[option].type}
                  value={options[option].label}
                  color="white"
                  className="body-1 z-50 flex cursor-pointer flex-row bg-white uppercase hover:bg-grey-3 hover:outline-none"
                >
                  <Select.ItemText className="body-1 uppercase">
                    {options[option].label}
                  </Select.ItemText>
                </Select.Item>
              )
            })}
          </Select.Group>
        </Select.Content>
      </Select.Root>
    </div>
  )
}

import clsx from 'clsx'
import { Block } from '@/lib/types'

type ImageInputProps = {
  error?: boolean
  block?: Block
  handleImageSelect: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export default function ImageInput(props: ImageInputProps) {
  const { error, block, handleImageSelect } = props

  return (
    <div>
      <div className={clsx(block?.data.image?.preview && 'hidden')}>
        <label
          htmlFor="file-upload"
          className={clsx(
            'body-1 flex h-[232px]  w-full cursor-pointer items-center justify-center border-1 text-grey-45',
            error ? 'border-red-55' : 'border-grey-45',
          )}
        >
          + Upload a JPG or PNG
        </label>
        <input
          id="file-upload"
          className="hidden"
          type="file"
          name="artwork"
          onChange={(e) => handleImageSelect(e)}
          accept=".jpg,.png,.jpeg"
        />
      </div>

      {block?.data.image?.preview && (
        <div className="mb-28">
          <img src={block.data.image.preview} alt="Preview" />
        </div>
      )}
    </div>
  )
}

'use client'

import clsx from 'clsx'
import React, { ChangeEvent } from 'react'
import EmptyCheckbox from '@/public/icons/emptyCheckbox'
import FilledCheckbox from '@/public/icons/filledCheckbox'

type CheckboxProps = {
  name: string
  label: string
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
  value: string | number | readonly string[] | boolean | undefined
  hide?: boolean
}

export default function Checkbox({
  name,
  value,
  label,
  handleChange,
  hide,
}: CheckboxProps) {
  return (
    <div className={clsx('flex flex-row ', hide && 'hidden')}>
      <input
        name={name}
        id={name}
        className="hidden"
        type="checkbox"
        onChange={(e) => handleChange(e)}
      />
      <label htmlFor={name} className=" flex w-fit text-14">
        {value ? (
          <FilledCheckbox
            className="ml-4 mt-[5px] h-12 w-12"
            width={12}
            height={12}
          />
        ) : (
          <EmptyCheckbox
            className="ml-4 mt-[5px] h-12 w-12"
            width={12}
            height={12}
          />
        )}
        <span className="ml-8 mt-[1px]">{label}</span>
      </label>
    </div>
  )
}

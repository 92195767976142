'use client'

import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { ContentNodeType, FileExtended } from '@/lib/types'
import { bytesToMb } from '@/lib/utils/bytesToMb'
import { truncateFilename } from '@/lib/utils/truncateFilename'
import Ellipsis from './tokens/Ellipsis'

type FileUploadListProps = {
  files: FileExtended[]
  handleChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  options?: { [key: string]: option }
  removeFileFromList?: (index: number) => void
  reorderFileInList?: (file: FileExtended, from: number, to: number) => void
  error?: string[]
  variant?: string
  hideLabel?: boolean
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  inputProps?: any
}

type option = {
  label: string
  type: string
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  callback: (value, index) => void
  hideLabel?: boolean
}

export default function FileUploadList({
  files,
  error,
  handleChange,
  removeFileFromList,
  reorderFileInList,
  options,
  variant = 'audio',
  hideLabel,
  inputProps,
}: FileUploadListProps) {
  const [multipleFiles, setMultipleFiles] = useState(false)
  useEffect(() => {
    setMultipleFiles(files.length > 1 || false)
  }, [files.length])

  const acceptFileTypes = variant === 'audio' ? '.mp3,.wav' : '*'
  const inputName = variant === 'audio' ? 'tracks' : 'bonusMaterial'

  const fileOptions = {
    remove: {
      label: 'Remove',
      type: 'remove',
      callback: (file: FileExtended) => {
        removeFile(file)
      },
    },
    moveUp: {
      label: 'Move up',
      type: 'moveUp',
      callback: (file: FileExtended) => {
        const index = files.indexOf(file)

        if (reorderFileInList) {
          reorderFileInList(file, index, index - 1)
        }
      },
    },
    moveDown: {
      label: 'Move down',
      type: 'moveDown',
      callback: (file: FileExtended) => {
        const index = files.indexOf(file)

        if (reorderFileInList) {
          reorderFileInList(file, index, index + 1)
        }
      },
    },
  }

  // if (index === 0) {
  //   delete fileOptions.moveUp
  // }

  // if (index === totalBlocks - 1) {
  //   delete fileOptions.moveDown
  // }

  const removeFile = (file: FileExtended) => {
    const index = files.indexOf(file)

    if (removeFileFromList) {
      removeFileFromList(index)
    }
  }

  return (
    <div className="">
      {!hideLabel && (
        <p className="ui-1 pb-8">
          {variant === 'audio' ? 'Tracks *' : 'Bonus Material *'}
        </p>
      )}
      {files.map((file, index) => {
        const isAudio = variant === 'audio'
        const isNotGate = file?.type !== ContentNodeType.Gate
        const title = isAudio ? file.title : file.description

        const placeholder = isAudio
          ? 'Enter track title'
          : 'Enter a short description'

        const fileName = isAudio || isNotGate ? file.name : file.fileName

        const fileSize = isNotGate
          ? bytesToMb(file.size)
          : bytesToMb(file.fileSize)

        const inputError =
          error && error[index] && title.length === 0 ? true : false

        return (
          <div
            key={index}
            className={clsx(
              'body-1 align-center mb-8 flex space-x-0 border-1 border-black py-[9px] pl-[12px] pr-[12px]',
              variant === 'audio' && 'h-[40px]',
              inputError && title.length === 0 && '!border-red-55',
              // multipleFiles && 'justify-btween',
            )}
          >
            <span
              className={clsx(
                'flex  gap-x-0 ',
                multipleFiles && '!w-[500px]  ',
              )}
            >
              {variant === 'audio' && <p className="w-[28px]">{index + 1}</p>}
              {multipleFiles && variant === 'audio' && (
                <>
                  <input
                    id={index.toString()}
                    type={'text'}
                    className={clsx(
                      'body-1 mr-8 w-full break-words placeholder-grey-45  focus:outline-none',
                    )}
                    name="trackTitle"
                    placeholder={placeholder}
                    onChange={handleChange}
                    contentEditable={true}
                    style={{
                      display: 'flex;',
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      // maxWidth: '212px',
                      overflow: 'auto',
                    }}
                    value={title}
                    ref={inputProps?.inputRef}
                  />
                </>
              )}
              {multipleFiles && variant === 'bonus' && (
                <textarea
                  id={index.toString()}
                  className={clsx(
                    'body-1 mr-8 w-full break-words placeholder-grey-45  focus:outline-none',
                  )}
                  style={{
                    resize: 'none',
                    overflowY: 'hidden',
                    minHeight: '48px',
                    height: 'auto',
                  }}
                  name="bonusMaterialDescription"
                  placeholder={placeholder}
                  onChange={handleChange}
                  value={title}
                  onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    e.target.style.height = 'auto'
                    e.target.style.height = e.target.scrollHeight + 'px'
                  }}
                />
              )}
            </span>

            <div className={clsx('body-1 flex w-full ', multipleFiles && ' ')}>
              <div className={clsx('flex', !multipleFiles && 'w-full')}>
                <abbr title={fileName} className="no-underline">
                  <p
                    className={clsx(
                      'body-1 b mr-8  line-clamp-1 cursor-default overflow-hidden text-right',
                      multipleFiles && 'w-[88px] text-right md:w-[140px]',
                    )}
                  >
                    {truncateFilename(fileName)}
                  </p>
                </abbr>
                <p className="body-1 mr-8 w-[60px] whitespace-nowrap text-right text-grey-45">
                  {fileSize}
                </p>
              </div>
              <div>
                <Ellipsis
                  options={options ?? fileOptions}
                  value={file}
                  parentIndex={index}
                  total={files.length}
                  fileType={file.type ?? ''}
                  lastParentIndex={files.length - 1}
                  align="left"
                />
              </div>
            </div>
          </div>
        )
      })}

      <label htmlFor="add-tracks">
        <p className=" body-1 border-box flex cursor-pointer items-center justify-center border-1 border-grey-45 px-16 py-[9px] text-grey-45 hover:opacity-80">
          {`+ Choose files to upload${
            variant === 'audio' ? ' (wav, mp3)' : ''
          }`}
        </p>
      </label>
      {error && <p className="caption-1 mt-8">{error[0]}</p>}

      <input
        id="add-tracks"
        type="file"
        name={inputName}
        multiple
        accept={acceptFileTypes}
        hidden
        onChange={handleChange}
      />
    </div>
  )
}

'use client'

import clsx from 'clsx'
import React from 'react'
import {
  ButtonColor,
  ButtonVariant,
  FormField,
  InputSelectOptionsProps,
} from '@/lib/types'
import checkIfSafari from '@/lib/utils/checkIfSafari'
import Button from './Button'
import Checkbox from './Checkbox'
import InputSelect from './InputSelect'

type InputProps = FormField & React.HTMLAttributes<HTMLInputElement>

export default function Input(props: InputProps) {
  const {
    name,
    label,
    placeholder,
    hint,
    value,
    type,
    required,
    disabled,
    handleChange,
    handleChangeWithoutEvent,
    error,
    inputProps,
    styleOverride,
  } = props

  const isSafari = checkIfSafari()

  // const textAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   handleChange(e as React.ChangeEvent<HTMLIxnputElement>)
  // }
  const inputClass = clsx(
    'border-1 body-1 !bg-white !rounded-none py-10 px-12 placeholder-grey-45 focus:outline-none placeholder:body-1 focus:inputFocus w-full box-border h-[40px]',
    inputProps?.currency && value !== undefined && value !== '' && 'pl-[22px]',
    error ? 'border-red-55' : 'border-black',
    disabled && 'opacity-20',
    styleOverride,
  )

  return (
    <div className="body-1 relative flex flex-col">
      {label && (
        <label
          htmlFor={name}
          className={clsx('!ui-1 pb-8', disabled && 'opacity-20')}
        >
          {label}
          {required && <span className=""> *</span>}
        </label>
      )}
      {type === 'textarea' && (
        <textarea
          id={name}
          name={name}
          placeholder={placeholder}
          value={value as string | number | readonly string[] | undefined}
          onChange={handleChange}
          disabled={disabled}
          className={inputClass + ' min-h-[120px] resize-none'}
        />
      )}

      {type === 'text' && (
        <input
          id={name}
          name={name}
          className={inputClass}
          placeholder={placeholder}
          value={value as string | number | readonly string[] | undefined}
          onChange={(e) => handleChange?.(e)}
          disabled={disabled}
          maxLength={inputProps?.max || undefined}
          ref={inputProps?.inputRef}
        />
      )}

      {type === 'number' && (
        <div
          className={clsx(inputProps?.siblingInput && 'flex flex-row gap-x-8')}
        >
          <div className="relative w-full">
            <>
              {inputProps?.childInput?.type === 'checkbox' && (
                <div className="absolute right-[12px] top-1/2 z-10 -translate-y-1/2">
                  <Checkbox
                    name={name}
                    value={inputProps?.childInput.value ?? false}
                    label={inputProps?.childInput.label ?? ''}
                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange?.(e)
                    }
                  />
                </div>
              )}
              {inputProps?.childInput?.type === 'select' && (
                <div className="absolute right-[12px] top-[10px]">
                  <InputSelect
                    options={
                      inputProps?.childInput?.options as InputSelectOptionsProps
                    }
                    handleChangeWithoutEvent={handleChangeWithoutEvent}
                    value={inputProps?.childInput?.value as string}
                    name={inputProps?.childInput?.name}
                  />
                </div>
              )}
            </>
            {inputProps?.currency && value !== undefined && value !== '' && (
              <label
                className={clsx(
                  'body-1 absolute  left-[12px]',
                  isSafari && 'bottom-[10px]',
                  !isSafari && 'top-[11px]',
                )}
              >
                {inputProps.currency}
              </label>
            )}
            {inputProps?.endIcon && value !== undefined && value !== '' && (
              <label className="absolute right-[12px] top-[11px]">
                {inputProps.endIcon}
              </label>
            )}
            <input
              id={name}
              name={name}
              className={inputClass}
              placeholder={placeholder}
              value={value as string | number | readonly string[] | undefined}
              disabled={
                (inputProps?.childInput?.type === 'checkbox' &&
                  inputProps?.childInput?.value === true) ??
                false
              }
              type="number"
              onChange={handleChange || handleChangeWithoutEvent}
              min={inputProps?.min}
              max={inputProps?.max}
              onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                e.currentTarget.blur()
              }
            />
          </div>
          {inputProps?.siblingInput?.type === 'button' && (
            <Button
              label={inputProps?.siblingInput?.label}
              variant={ButtonVariant.Large}
              color={ButtonColor.Blue}
            />
          )}

          {inputProps?.siblingInput?.type === 'select' && (
            <InputSelect
              options={
                inputProps?.siblingInput?.options as InputSelectOptionsProps
              }
              handleChangeWithoutEvent={handleChangeWithoutEvent}
              value={inputProps?.siblingInput?.value as string}
              name={inputProps?.siblingInput?.name}
            />
          )}
        </div>
      )}

      {error && <p className="caption-1 pt-[8px]">{error[0]}</p>}
      {hint && (
        <p className={clsx('caption-1 pt-8 text-12 text-grey-45')}>{hint}</p>
      )}
    </div>
  )
}

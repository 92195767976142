'use client'

import Icon from './Icon'

type RangeInputProps = {
  value: number
  min: number
  max: number
  step: number
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export default function RangeInput(props: RangeInputProps) {
  const { value, min, max, step, onChange } = props

  return (
    <div className="flex w-full items-center justify-between">
      <span className="pr-4">
        <Icon name="minus" className="grey-45" width={16} height={16} />
      </span>
      <input
        type="range"
        value={value}
        min={min}
        max={max}
        step={step}
        aria-label="Zoom"
        onChange={onChange}
        className="h-[1px] w-[191px] bg-grey-45"
      />

      <span className="pl-4">
        <Icon name="plus" className="grey-45" width={16} height={16} />
      </span>
    </div>
  )
}

'use client'

import clsx from 'clsx'
import debounce from 'lodash.debounce'
import { useCallback, useEffect, useState } from 'react'
import Icon from './Icon'

// import Nullable from '@/lib/types'

export interface SearchInputProps
  extends React.HTMLAttributes<HTMLInputElement> {
  placeholder: string
  name: string
  textArea?: boolean
  disabled?: boolean
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleClear: (e: React.MouseEvent) => void
  inputStyleOverride?: string
  inSearchPage?: boolean
  query: string
  setQuery?: React.Dispatch<React.SetStateAction<string>>
  view?: string
  searchInputRef?: React.RefObject<HTMLInputElement>
}

export default function SearchInput(props: SearchInputProps) {
  const {
    placeholder,
    name,
    handleChange,
    handleClear,
    inputStyleOverride,
    inSearchPage = false,
    query,
    setQuery,
    view = 'desktop',
    searchInputRef,
  } = props

  const [inputValue, setInputValue] = useState<string>(query)
  useEffect(() => {
    //clears filter when navigating from searchResults view

    if (!inSearchPage && setQuery) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setQuery(null)
      setInputValue('')
    }
  }, [])

  const handleSubmit = (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault()
    }

    debouncedSetQuery(inputValue)
  }

  const debouncedSetQuery = useCallback(
    debounce((searchQuery: string) => {
      if (setQuery) {
        setQuery(searchQuery)
      }
    }, 300),
    [],
  )

  const mobileStylingProp =
    '!w-[232px] text-16 body-1  appearance-none !border-none !py-0 pl-4 pr-[32px] text-right !leading-[16px] !tracking-2 md:w-[184px] md:border-1 md:py-4 md:pl-12 md:text-left focus:outline-none'

  const desktopStylingProp = `text-16  body-1 focus:inputFocus h-[28px] w-[184px] rounded-none border-1 bg-white py-4  pl-12 placeholder-grey-45 focus:outline-none md:text-14 md:w-[200px] md:py-1 lg:pl-[40px] `

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div
        className={clsx(
          ' relative block w-full',
          !inSearchPage && '!h-[20px]  md:!h-[unset]',
        )}
      >
        {inSearchPage && (
          <span className="w-18 h-18 pointer-events-none absolute inset-y-0 left-0 top-0 hidden items-center pl-8 md:flex md:pl-12">
            <Icon className="black" width={20} height={20} name="searchLarge" />
          </span>
        )}
        {!inSearchPage && (
          <span className="pointer-events-none absolute inset-y-0 left-0 top-0 hidden items-center pl-8  md:flex">
            <Icon className="black" width={20} height={20} name="searchSmall" />
          </span>
        )}
        <input
          className={clsx(
            view === 'desktop' && ` ${desktopStylingProp}`,
            view === 'mobile' && ` ${mobileStylingProp}`,
            !inSearchPage && 'flex !pb-[1px] !pt-0 md:!pl-[34px] md:pr-[58px]',
            inSearchPage && 'pr-[65px] md:!pl-[42px]',
            inputStyleOverride,
          )}
          placeholder={placeholder}
          name={name}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value)
            debouncedSetQuery(e.target.value)

            if (handleChange) {
              handleChange(e)
            }
          }}
          autoComplete="off"
          type={'text'}
          ref={searchInputRef}
        />
        {view === 'mobile' && (
          <button
            className="absolute right-0 top-1/2 flex -translate-y-1/2 cursor-pointer items-center"
            onClick={(e) => {
              e.stopPropagation()
              handleClear(e)
              setInputValue('')
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setQuery && setQuery(null)
            }}
            type="reset"
          >
            <Icon className="black" width={20} height={20} name="closeLarge" />{' '}
          </button>
        )}
        {query && query?.length > 0 && view === 'desktop' && (
          <button
            className={clsx(
              'absolute inset-y-0 right-0 top-0 flex cursor-pointer items-center',
              inSearchPage ? 'pr-[12px]' : 'pr-[8px]',
            )}
            onClick={(e) => {
              e.stopPropagation()
              handleClear(e)
              setInputValue('')
            }}
            type="reset"
          >
            <p className="ui-1 pb-[0.5px] text-grey-45">Clear</p>
          </button>
        )}
        <button type="submit" className="border-10 hidden border-blue-82">
          submit
        </button>
      </div>
    </form>
  )
}

export const truncateFilename = (filename: string) => {
  if (!filename) {
    return ''
  }

  if (filename.length < 11) {
    return filename
  }

  const name = filename.substring(0, 6)
  const extension = filename.substring(filename.length - 5, filename.length)

  return `${name}...${extension}`
}

'use client'

import ImgixClient from '@imgix/js-core'

type ImageLoaderProps = {
  src: string
  width: number
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function loader({ src, width }: ImageLoaderProps) {
  try {
    if (!src.includes('http')) return src

    if (!src.includes('arweave') && !src.includes('imgix')) return src

    if (src.includes('?fallback=true')) return src.replace('?fallback=true', '')

    const client = new ImgixClient({
      domain: process.env.IMGIX_URL!,
      secureURLToken: process.env.IMGIX_TOKEN,
    })

    const urlString = client.buildURL(src, {
      w: width,
      fm: 'webp',
    })

    const url = new URL(urlString)

    if (src.includes('&s=')) {
      let fixedURL = src.replace(`&s=${url.searchParams.get('s')}`, '')

      return (fixedURL += `&s=${url.searchParams.get('s')}`)
    }

    return url
  } catch (error) {
    console.error(error)

    return src
  }
}

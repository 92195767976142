'use client'

import { FileExtended, FormField } from '@/lib/types'
import FileUploadList from './FileUploadList'

type FileUploaderProps = FormField & {
  handleNextStep?: (e: React.MouseEvent<HTMLElement>) => void
  removeFileFromList?: (index: number) => void
  reorderFileInList?: (file: FileExtended, from: number, to: number) => void
  variant?: string
  options?: { [key: string]: option }
  currentStep?: number
  // value: File[]
}

type option = {
  label: string
  type: string
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  callback: (value, index) => void
}

export default function FileUploader({
  // label,
  handleChange,
  value,
  error,
  removeFileFromList,
  reorderFileInList,
  variant = 'audio',
  options,
  currentStep,
  inputProps,
}: FileUploaderProps) {
  const hideLabel = currentStep === 0

  return (
    <div className="body-1 w-full">
      <FileUploadList
        files={value as FileExtended[]}
        handleChange={handleChange}
        removeFileFromList={removeFileFromList}
        reorderFileInList={reorderFileInList}
        error={error as string[]}
        variant={variant}
        options={options}
        hideLabel={hideLabel}
        inputProps={inputProps}
      />
    </div>
  )
}
